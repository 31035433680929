<template>
  <b-overlay :show="isDeletingCmsFile">
    <b-form @submit.prevent="onUploadFiles()">
      <b-row>
        <b-col cols="10">
          <b-form-file
            id="files"
            v-model="uploadFileForm.files"
            multiple
            class="mb-2"
          />
        </b-col>
        <b-col>
          <b-overlay :show="isUploadingCmsFiles">
            <b-button
              variant="success"
              :disabled="uploadFileForm.files.length === 0"
              block
              type="submit"
            >
              Upload
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>
    </b-form>
    <hr>
    <div class="p-1 file-manager-container">
      <b-table
        :fields="fields"
        :items="cmsSiteFiles"
        small
        :busy="isFetchingCmsSiteFiles"
        responsive
      >
        <template #cell(publicPath)="data">
          <a
            :href="data.value"
            target="_blank"
          >
            <b-img-lazy
              :src="data.value"
              fluid
              center
              blank-color="#eee"
              thumbnail
              style="max-width: 150px"
            />
          </a>
        </template>
        <template #cell(fileName)="data">
          <h6> {{ data.value }}</h6>
          <small>{{ data.item.publicPath }}</small>
        </template>
        <template #cell(id)="data">
          <b-button-group>
            <b-button
              variant="info"
              size="sm"
              @click="onCopyPath(data.value)"
            >
              Copy
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              @click="onPopupConfirmRemove(data.value)"
            >
              Remove
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      uploadFileForm: {
        files: [],
      },
      fields: [
        '#',
        {
          key: 'publicPath',
          label: 'Image',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'fileName',
          label: 'name & public path',
          thStyle: {
            maxWidth: '120px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingCmsSiteFiles: (state) => state.cms.isFetchingCmsSiteFiles,
      cmsSiteFiles: (state) => state.cms.cmsSiteFiles,
      isUploadingCmsFiles: (state) => state.cms.isUploadingCmsFiles,
      isDeletingCmsFile: (state) => state.cms.isDeletingCmsFile,
      cmsSite: (state) => state.cms.cmsSite,
    }),
    siteId() {
      return this.cmsSite.cmsSiteMasterId
        ? this.cmsSite.cmsSiteMasterId
        : this.cmsSite.id
    },
  },
  watch: {
    async cmsSite(val) {
      if (val) {
        if (this.cmsSite.cmsSiteMasterId.length > 0) {
          await this.fetchCmsSiteFiles(this.cmsSite.cmsSiteMasterId)
        } else {
          await this.fetchCmsSiteFiles(this.cmsSite.id)
        }
      }
    },
  },
  methods: {
    ...mapActions(['fetchCmsSiteFiles', 'uploadCmsFiles', 'removeCmsFile']),
    onUploadFiles() {
      const formData = new FormData()
      for (const file of this.uploadFileForm.files) {
        formData.append('files', file)
      }
      this.uploadCmsFiles({ siteId: this.siteId, formData })
    },
    onCopyPath(id) {
      const publicPath = this.cmsSiteFiles.find((sf) => sf.id === id).publicPath
      const el = document.createElement('textarea')
      el.value = publicPath
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    onPopupConfirmRemove(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure? you want to remove.')
        .then(() => {
          this.removeCmsFile({ siteId: this.siteId, fileId: id })
        })
    },
  },
}
</script>
